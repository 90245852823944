<template>
  <svg
    viewBox="0 0 508 508"
    style="enable-background: new 0 0 20 20; width: 25px; height: 25px"
  >
    <g>
      <path
        style="fill: #40596b"
        d="M90.15,319.7L20.75,440l81-4.3l36.8,72.3l64.2-111.3C156.75,384.8,117.25,357.1,90.15,319.7z"
      />
      <path
        style="fill: #40596b"
        d="M417.35,319.1c-26.9,37.5-66.4,65.3-112.3,77.4l64.4,111.5l36.8-72.3l81,4.3L417.35,319.1z"
      />
    </g>
    <circle
      :style="`fill: ${getFill(ranking).primary}`"
      cx="253.55"
      cy="201.7"
      r="201.7"
    />
    <circle
      :style="`fill: ${getFill(ranking).secondary}`"
      cx="253.55"
      cy="201.7"
      r="146.2"
    />
    <text x="9" y="18" style="fill: white; transform: scale(16.5)">
      {{ ranking }}°
    </text>
  </svg>
</template>
<script>
export default {
  props: {
    ranking: {
      type: Number,
    },
    fill: {
      type: Object,
    },
  },
  methods: {
    getFill(ranking) {
      switch (ranking) {
        case 1:
          return {
            primary: "#ecc95e",
            secondary: "#e0b54e",
          };
        case 2:
          return {
            primary: "#e4e4e4",
            secondary: "#afb0b1",
          };
        case 3:
          return {
            primary: "#d08531",
            secondary: "#e9a043",
          };
        case 4:
          return {
            primary: "#676050",
            secondary: "#544e41",
          };
        default:
          return {
            primary: "#8d96bd",
            secondary: "#5f6993",
          };
      }
    },
  },
};
</script>
